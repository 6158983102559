<template>
  <v-container fluid>
    <Header
      :status="filter.status"
      :activeCount="activeCount"
      :archivedCount="archivedCount"
      @update-status="updateFilterStatus"
      @create-new-tag="onCreateTag"
    />

    <v-card class="py-6 px-6 mt-3" rounded="lg" elevation="0" width="100%">
      <template v-if="hasNoTags && isLoading || hasTags">
        <v-row justify="space-between" class="align-center">
          <v-col cols="12" md="auto" class="d-flex align-center">
            <SearchComponent
              :search="filter.name"
              @update:search="filter.name = $event"
              :placeholder="$t('placeHolder.searchByName')"
            />

            <FilterDialog @update-filter-types="updateFilterTypes" />
          </v-col>

          <v-col cols="12" md="auto">
            <v-row justify="end" class="align-center">
              <SettingsMenu :filterItems="filteredMenuHeaders" />
            </v-row>
          </v-col>
        </v-row>

        <TagTable
          :headers="filteredHeaders"
          :items="filteredTags"
          itemKey="uid"
          @edit-tag="onEditTag"
          @archive-tag="onArchiveTag"
          @delete-tag="onDeleteTag"
        />
      </template>

      <EmptyTag v-else @create-new-tag="onCreateTag" />

      <CreateUpdateDialog
        v-model="showCreateUpdateDialog"
        :data="selectedTag"
        @create-new-tag="createTag"
        @update-tag="updateTag"
        @close-dialog="showCreateUpdateDialog = false"
      />

      <ArchiveConfirmDialog
        v-model="showArchiveConfirmDialog"
        :tagName="selectedTag.name"
        @archive-tag="archiveTag"
      />

      <DeleteConfirmDialog
        v-model="showDeleteConfirmDialog"
        :tag="selectedTag"
        @delete-tag="deleteTag"
      />
    </v-card>
  </v-container>
</template>

<script>
import * as _ from 'lodash'
import { createNamespacedHelpers  } from 'vuex';

import makeTagService from '@/services/api/tag';

import Header from '@/components/Admin/Tag/Header';
import FilterDialog from '@/components/Admin/Tag/FilterDialog.vue';
import SearchComponent from '@/components/Project/SearchComponent.vue';
import SettingsMenu from '@/components/Project/SettingsMenu.vue';
import TagTable from '@/components/Admin/Tag/TagTable.vue';
import EmptyTag from '@/components/Admin/Tag/EmptyTag.vue';
import CreateUpdateDialog from '@/components/Admin/Tag/CreateUpdateDialog.vue';
import ArchiveConfirmDialog from '@/components/Admin/Tag/ArchiveConfirmDialog.vue';
import DeleteConfirmDialog from '@/components/Admin/Tag/DeleteConfirmDialog.vue';

const { mapState } = createNamespacedHelpers('user');

export default {
  name: 'Tags',

  components: {
    Header,
    FilterDialog,
    SearchComponent,
    SettingsMenu,
    TagTable,
    EmptyTag,
    CreateUpdateDialog,
    ArchiveConfirmDialog,
    DeleteConfirmDialog,
  },

  data() {
    return {
      filter: {
        name: '',
        status: 'active',
        entityTypes: [],
      },
      originalTags: [],
      filteredTags: [],
      headers: [
        {
          text: this.$t('name'),
          align: 'start',
          sortable: true,
          value: 'name',
          class: 'elevation-0 rounded-l-lg',
          checked: true,
        },
        {
          text: this.$t('description'),
          value: 'description',
          sortable: true,
          checked: true,
        },
        {
          text: this.$t('tagPage.type'),
          value: 'entityTypes',
          sortable: true,
          checked: true
        },
        {
          text: this.$t('tagPage.count'),
          value: 'count',
          sortable: true,
          width: 100,
          checked: true,
        },
        {
          text: '',
          value: 'uid',
          sortable: false,
          class: 'rounded-r-lg',
          width: 60,
          checked: true
        },
      ],
      isLoading: false,
      errorMessage: '',
      showCreateUpdateDialog: false,
      showArchiveConfirmDialog: false,
      showDeleteConfirmDialog: false,
      selectedTag: {
        uid: '',
        name: '',
        description: '',
        entityTypes: [],
      }
    }
  },

  computed: {
    ...mapState(['currentAccount']),
    filteredMenuHeaders(){
      const filtered = this.headers.filter((header) => header.value != 'actions');
      return filtered;
    },
    filteredHeaders() {
      return this.headers.filter((header) => {
        const filterItem = this.filteredMenuHeaders.find((item) => item.text === header.text);
        return filterItem ? filterItem.checked : true;
      });
    },
    activeCount() {
      return this.originalTags.filter(item => !item.archivedAt).length
    },

    archivedCount() {
      return this.originalTags.filter(item => !!item.archivedAt).length
    },

    hasTags() {
      return this.originalTags.length > 0
    },
    hasNoTags() {
      return this.originalTags.length === 0
    },
  },

  watch: {
    'filter.name': {
      handler: _.debounce(function () {
        this.filterTags()
      }, 500),
    },
  },

  async mounted() {
    await this.init(this.currentAccount.handle);
  },
  async beforeRouteUpdate(to, from, next) {
    const handle = to.params.handle;
    if (handle && handle !== from.params.handle) {
      try {
        await this.init(handle);
        next();
      } catch (error) {
        next();
      }
    } else {
      console.log("not run")
      next();
    }
  },

  methods: {
    async init(handle){
      await this.getTags(handle);
    },
    async getTags(handle) {
      const tagService = makeTagService(this.$api);
      
      try {
        this.isLoading = true;
        const response = await tagService.getTags(handle);
        
        if(response.status == 200) {
          this.originalTags = response.data
        }
        this.isLoading = false;
        
      } catch (err) {
        // this.isLoading = true
        this.errorMessage = err.message;
      }

      this.filterTags();
    },

    updateFilterStatus(value) {
      this.filter.status = value;

      this.filterTags();
    },

    updateFilterTypes(value) {
      this.filter.entityTypes = value

      this.filterTags();
    },

    filterTags() {
      let filteredTags = _.cloneDeep(this.originalTags)

      if (this.filter.name) {
        const searchName = this.filter.name.toLowerCase();
        filteredTags = filteredTags.filter(item => item.name.toLowerCase().includes(searchName));
      }

      if (this.filter.status) {
        filteredTags = filteredTags.filter(item => this.filter.status === 'active' && !item.archivedAt || this.filter.status === 'archived' && item.archivedAt)
      }

      if (this.filter.entityTypes.length > 0) {
        filteredTags = filteredTags.filter(item => this.filter.entityTypes.find(tagType => item.entityTypes.includes(tagType.name)))
      }

      this.filteredTags = filteredTags
    },

    onCreateTag() {
      this.selectedTag = {
        uid: '',
        name: '',
        description: '',
        entityTypes: [],
      }

      this.showCreateUpdateDialog = true
    },

    async createTag(tag) {
      this.showCreateUpdateDialog = false

      const tagService = makeTagService(this.$api);
      this.isLoading = true
      try {
        const response = await tagService.createTag(this.currentAccount.handle, tag);
        this.originalTags.push(response.data)
      } catch (err) {
        this.isLoading = true
        this.errorMessage = err.message;
      }

      this.filterTags();
    },

    onEditTag(tag) {
      this.selectedTag = {
        uid: tag.uid,
        name: tag.name,
        description: tag.description,
        entityTypes: tag.entityTypes,
        archivedAt: tag.archivedAt,
      }

      this.showCreateUpdateDialog = true
    },

    async updateTag(tag) {
      this.showCreateUpdateDialog = false

      const tagService = makeTagService(this.$api);
      this.isLoading = true
      try {
        const response = await tagService.updateTag(this.currentAccount.handle, tag);

        this.originalTags = this.originalTags.map(item => {
          if (item.uid !== tag.uid) {
            return item
          }

          return response.data
        })
      } catch (err) {
        this.isLoading = true
        this.errorMessage = err.message;
      }

      this.filterTags();
    },

    onArchiveTag(tag) {
      this.selectedTag = {
        uid: tag.uid,
        name: tag.name,
        description: tag.description,
        entityTypes: tag.entityTypes,
        archivedAt: tag.archivedAt ? null : new Date(),
      }

      if (tag.archivedAt) {
        return this.updateTag(this.selectedTag)
      }

      this.showArchiveConfirmDialog = true
    },

    async archiveTag() {
      this.showArchiveConfirmDialog = false
      this.updateTag(this.selectedTag)
    },

    onDeleteTag(tag) {
      this.selectedTag = {
        uid: tag.uid,
        name: tag.name,
        description: tag.description,
        entityTypes: tag.entityTypes,
        count: tag.count,
        archivedAt: tag.archivedAt,
      }

      this.showDeleteConfirmDialog = true
    },

    async deleteTag() {
      this.showDeleteConfirmDialog = false
      const tagService = makeTagService(this.$api);

      try {
        await tagService.deleteTag(this.currentAccount.handle, this.selectedTag.uid)

        this.originalTags = this.originalTags.filter(item => item.uid !== this.selectedTag.uid)
        } catch (err) {
        this.isLoading = true
        this.errorMessage = err.message;
      }

      this.filterTags()
    },
  }
}
</script>